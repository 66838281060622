import React, { useState, useEffect, useContext, useRef } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PatientpageContext } from './../../context/GlobalContext';
import { constants } from './../../utility/constants';
import Carousel from 'nuka-carousel/lib/carousel';
import { LoginImage } from '../../static/ImageManager';
import LoginButton from '../../Tools/LoginButton';
import flagIcon from '../../static/images/india-flag-icon.svg';
import { baseUrl } from './../../utility/constants';
import { OPD } from '../../calls/api';
import {
  TextField,
  Select,
  MenuItem,
  Grid,
  Paper,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { countriesList, Ls } from './../../utility/utility';
import { Create, MobileFriendlyOutlined } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { ServiceCall } from './../../utility/service-call';
import SnackBarComponent from '../../components/SnackBarComponent';
import { red } from '@material-ui/core/colors';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CountdownButton from '../../components/CountdownButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Switch } from '@mui/material';
const useStyles = makeStyles((theme) => {
  return {
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  };
});

const defaultError = {
  phoneNumber: {
    hasError: false,
    errorMsg: '',
  },
  remember: {
    hasError: false,
    errorMsg: '',
  },
};
const defaultFormData = {
  phoneNumber: '',
  remember: true,
};
export default function LogIn() {
  let history = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const redirectFrom = queryParams.get('redirectfrom');

  const classes = useStyles();
  const [phoneCode, setPhoneCode] = useState('+91');
  const [loginLoader, setLoginLoader] = useState(false);
  const [otpLoader, getOtpLoader] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [formError, setFormError] = useState(defaultError);
  const [formData, setFormData] = useState(defaultFormData);
  const [otp, setOtp] = useState('');
  const [apiError, setApiError] = useState('');
  const [otpContent, setOtpContent] = useState('');
  const [disableSendOtp, setDisableOtp] = useState(true);
  const timer = useRef(null);
  const [showTimer, setShowTimer] = useState(6000);
  const [otpValidation, setOtpValidation] = useState({
    hasError: false,
    content: '',
  });
  const [apiResponse, setApiResponse] = useState('');
  const [showwhatps, setShowwhatps] = useState(true);
  const otpField = useRef(null);
  const patientContext = useContext(PatientpageContext);
  // const { search } = useLocation();

  const handleFollowUP = async (checked) => {
    setShowwhatps(checked);
  };

  useEffect(() => {
    Ls.remove('userToken');
    // const queryParams = new URLSearchParams(search);
    // const redirectFrom = queryParams.get("redirectfrom");
    // if (history.action === "PUSH" && !redirectFrom) {
    //   // This means the user accessed the login page directly
    //   queryParams.delete("redirectfrom");
    //   history.replace({ search: queryParams.toString() });
    // }
    return function () {
      clearTimeout(timer.current);
    };
  }, []);
  useEffect(() => {
    if (showOtp) {
      otpButtonTimer();
      otpField.current.focus();
    }
  }, [showOtp]);

  // console.log("login patientContext.slectedAppointment",patientContext.slectedAppointment)

  const otpButtonTimer = function () {
    timer.current = setTimeout(() => {
      setDisableOtp(false);
    }, 60000);
  };
  const handleChange = (event) => {
    setPhoneCode(event.target.value);
  };

  const patientLogin = async () => {
    setLoginLoader(true);
    try {
      let loginSuccess = await ServiceCall.postv2(OPD?.patientLogin, '', {
        phone: phoneCode + formData.phoneNumber,
        otp: otp,
        hos_id: constants.hospitalId('hosp_id'),
      });
      if (loginSuccess) {
        localStorage.setItem('phone', formData.phoneNumber);
        setApiResponse({ ...loginSuccess.data, phone: formData.phoneNumber });
        setLoginLoader(false);
        Ls.set('pt_token', loginSuccess.data.pt_token);
        Ls.set('pt_key', loginSuccess.data.pt_key);
        if (redirectFrom) {
          history.push({
            pathname: redirectFrom ? redirectFrom : '/',
            search: search,
          });
        } else {
          // If there is no "redirectfrom" parameter
          history.push({
            pathname: '/',
            search: search,
          });
        }
      }
    } catch (err) {
      setLoginLoader(false);
      if (err.response) {
        setApiError(err.response.data.message);
        setOtpValidation({
          hasError: true,
          content: err.response.data.message,
        });
      } else if (err.request) {
        setApiError('Failed request ' + err);
      } else {
        setApiError('Failed in general ' + err);
      }
    }
  };

  const Card = ({ data, className }) => (
    <div className={`${className}__box`}>
      <div className={`${className}__image`}>
        <img src={data.image} />
      </div>
      <div className={`${className}__text`}>{data?.text}</div>
    </div>
  );

  const getOtp = async () => {
    getOtpLoader(true);
    setDisableOtp(true);

    try {
      let otpResponse = await ServiceCall.postv2(OPD?.getOtp, '', {
        phone: phoneCode + formData.phoneNumber,
        hos_id: constants.hospitalId('hosp_id'),
        wa_otp: showwhatps,
      });
      if (otpResponse) {
        getOtpLoader(false);
        setShowOtp(true);
        patientContext?.setState({
          msg: otpResponse?.data.message || '',
          type: 'success',
          timer: null,
          vertical: 'top',
          horizontal: 'center',
        });
        setOtpContent(otpResponse?.data.message);
        // if (!showOtp) {
        //   setShowOtp(true);
        //   setOtpContent(otpResponse.data.message);
        // } else {
        //   otpButtonTimer();
        // }
      }
    } catch (err) {
      getOtpLoader(false);
      if (err.response) {
        setApiError(err.response.data.message);
        setOtpValidation({
          hasError: true,
          content: err.response.data.message,
        });
      } else if (err.request) {
        setApiError('Failed request ' + err);
      } else {
        setApiError('Failed in general ' + err);
      }
    }
  };
  const errorHandling = (fieldName, value) => {
    let errorField = formError[fieldName];
    if (fieldName === 'phoneNumber') {
      if (value.trim() === '' || value.length !== 10 || isNaN(value)) {
        errorField.hasError = true;
        errorField.errorMsg = 'Invalid phone number';
      } else {
        errorField.hasError = false;
        errorField.errorMsg = '';
      }
      setFormError({
        ...formError,
        [fieldName]: errorField,
      });
    }
  };
  const updateForm = (e) => {
    let fieldName = e.target.name;
    let value = '';

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    errorHandling(fieldName, value);
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const submitPhoneNo = (e) => {
    e.preventDefault();
    let formErrorbolean = false;

    Object.keys(formData).map((item) => {
      errorHandling(item, formData[item]);
    });
    Object.keys(formError).map((item) => {
      if (formError[item].hasError) {
        formErrorbolean = true;
      }
    });
    if (!formErrorbolean) {
      getOtp();
    }
  };
  const setOtpValue = (e) => {
    let value = e.target.value;
    if (!value.trim()) {
      setOtpError(true);
    } else {
      setOtpError(false);
    }
    if (isNaN(value)) {
      setOtpValidation({ hasError: true, content: 'Incorrect Entry' });
    } else {
      setOtpValidation({ hasError: false, content: '' });
    }
    if (value.length <= 6) {
      setOtp(value);
    }
  };
  const resetOtp = () => {
    setFormError(defaultError);
    setFormData(defaultFormData);
    setShowOtp(false);
    setOtpValidation({ hasError: false, content: '' });
  };
  // const onCloseEvent = () => setApiError('');
  function onKeyPress(e) {
    if (e.key === 'Enter') {
      if (otp.length === 6) {
        patientLogin();
      }
    }
  }
  var dynamicWidth = `calc(100vh - 56px)`;

  return (
    <>
      <Header page="login" apiResponse={apiResponse} />
      <div className="loginContainer bodyBg">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          {/* <SnackBarComponent onCloseEvent={onCloseEvent} apiError={apiError} /> */}
          <form noValidate autoComplete="off">
            {/* <div className="whtsp_switch">
                  <span className="label_with_icon">
                    <span className="label_text">Get OTP on</span>
                    <WhatsAppIcon />
                  </span>
                  <Swtich
                    labelInput="remind_patient"
                    checked={showwhatps}
                    // ContainerClass="prescriptionpage__medication__reminder__switch"
                    handleChange={(e) => {
                      const isChecked = e.target.checked;
                      handleFollowUP(isChecked);
                      // getOtp(isChecked); // Call getOtp with the switch status
                    }}
                  />
                </div> */}
            <Paper className="py-2 px-4 loginContainer_login">
              <Carousel
                nextDisabled={true}
                previousDisabled={true}
                renderCenterLeftControls={false}
                renderCenterRightControls={false}
                className="loginContainer_carousel"
              >
                {LoginImage &&
                  Array.isArray(LoginImage) &&
                  LoginImage?.map((element) => (
                    <Card
                      data={element}
                      className="loginContainer_carousel_card"
                    />
                  ))}
              </Carousel>

              <div>
                <div className="whtsp_switch">
                  <span className="label_with_icon">
                    <span className="label_text">Get OTP on</span>
                    <WhatsAppIcon />
                  </span>
                  <Switch
                    checked={phoneCode === '+91' ? showwhatps : true} // Always true for non-India codes
                    // onChange={handleFollowUP}
                    disabled={phoneCode !== '+91'} // Only enable when phoneCode is '+91'
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      handleFollowUP(isChecked);
                    }}
                  />
                </div>
                <span className="login-text">
                  Enter Phone Number to continue
                </span>
                <Grid
                  container
                  className="loginContainer_number"
                  justifyContent="center"
                >
                  <Grid item xs={1} className="loginContainer_icon">
                    {/* <MobileFriendlyOutlined /> */}
                    {/* <span class="material-symbols-outlined icon-glow">
                      call
                    </span> */}
                    <img src={flagIcon} />
                  </Grid>
                  <Grid item xs={2} className=" loginContainer_country">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={phoneCode}
                      onChange={handleChange}
                      // disabled
                      mt={2}
                      className="w-100"
                    >
                      {countriesList
                        .slice() // create a copy to avoid mutating original array
                        .sort((a, b) => a.code.localeCompare(b.code)) // sort alphabetically
                        .map((country, index) => (
                          <MenuItem
                            key={`${country.code}-${index}`}
                            value={country.code}
                            style={{ marginRight: '5rem  !important' }}
                          >
                            {country.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={7} className="">
                    <TextField
                      error={formError.phoneNumber.hasError}
                      className=" loginContainer_phone-input"
                      type="tel"
                      name="phoneNumber"
                      id="standard-basic"
                      placeholder="Enter your number"
                      onChange={updateForm}
                      value={formData.phoneNumber}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <LoginButton
                    onSubmit={submitPhoneNo}
                    loader={otpLoader}
                    color="#199a8e"
                    loginClass={
                      formError?.phoneNumber?.hasError ? 'd-none' : ''
                    }
                    disabled={
                      otpLoader || patientContext?.hospitalError?.hasError
                    }
                  />
                  {/* <button
                    className="Arrow--right"
                    onClick={submitPhoneNo}
                    disabled={
                      parseJson(Ls.get("hosp_id")) == null ? true : false
                    }
                  >
                    {otpLoader ? (
                      <CircularProgress size={16} color="success" />
                    ) : (
                      <img src={ContinueIcon} />
                    )}
                  </button> */}
                </Grid>
                {formError.phoneNumber.hasError && (
                  <FormHelperText className="validation-error">
                    {formError.phoneNumber.errorMsg}
                  </FormHelperText>
                )}
                {/* <FormControl error={formError.remember.hasError}>
                  {formError.remember.hasError && (
                    <FormHelperText className="validation-error">
                      {formError.remember.errorMsg}
                    </FormHelperText>
                  )}
                </FormControl> */}
                {showOtp && !formError?.phoneNumber?.hasError ? (
                  <Paper className="loginContainer_otp">
                    <div>
                      <div className="pt-2 loginContainer_otp__box">
                        <div className="loginContainer_otp__input">
                          <TextField
                            className="loginContainer_otp__input__box"
                            type="text"
                            placeholder="Enter OTP"
                            inputProps={{ min: 0, max: 999999, maxLength: 6 }}
                            onChange={setOtpValue}
                            value={otp}
                            inputRef={otpField}
                            onKeyPress={onKeyPress}
                          />
                          <LoginButton
                            onSubmit={patientLogin}
                            loader={loginLoader}
                            color="#199a8e"
                            loginClass={otp.length < 6 ? 'd-none' : ''}
                            disabled={loginLoader || otpError || otp.length < 6}
                          />
                        </div>
                        {otpValidation.hasError && (
                          <FormHelperText className="validation-error">
                            {otpValidation.content}
                          </FormHelperText>
                        )}
                        <Box className="d-flex justify-content-center pt-2">
                          <Box className="loginContainer_otp_button-container"></Box>
                        </Box>
                      </div>
                    </div>
                  </Paper>
                ) : (
                  <></>
                )}
              </div>
            </Paper>
          </form>
        </Container>
        <Footer />
      </div>
    </>
  );
}
